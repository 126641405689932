
<template>
  <div>
    <div class="home"
         :style="'width:' + winWCur + 'px;height:' + winHCur + 'px;margin-top:' + winTCur + 'px'"
    >
      <div :style="'height:' + winHCur + 'px;' + 'position: relative'">
        <video
            class="video"
            id="video33"
            muted
            autoplay
            loop
            src="../assets/videos/33.mp4"
        ></video>
        <!--首页-->
<!--        <div class="cur btn-left" @click="$router.push({ path:'/32', query: $route.query})"></div>-->
<!--        <div class="cur btn-right" @click="$router.push({ path:'/4', query: $route.query})"></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { myMixins } from "../assets/js/mixin";
export default {
  mixins: [myMixins],
  data() {
    return {
    };
  },
  mounted() {
    let video = document.getElementById('video33')
    video.play();
  },
  methods: {
  }
};
</script>

