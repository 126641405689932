
<template>
  <div>
    <div class="home"
         :style="'width:' + winWCur + 'px;height:' + winHCur + 'px;margin-top:' + winTCur + 'px'"
    >
      <div :style="'height:' + winHCur + 'px;' + 'position: relative'">
        <video
            v-show="idx==0"
            class="video"
            id="video0"
            muted
            autoplay
            loop
            src="../assets/videos/01.mp4"
        ></video>
        <!--首页-->
        <div v-if="idx>0" class="cur btn-left" @click="idx=idx-1"></div>
        <div v-if="idx<5" class="cur btn-right" @click="idx=idx+1"></div>
      </div>
    </div>
    <div class="p-box">
      <p2 v-if="idx==1"/>
      <p31 v-if="idx==2"/>
      <p32 v-if="idx==3"/>
      <p33 v-if="idx==4"/>
      <p4 v-if="idx==5"/>
    </div>
    <div v-if="isAndroid && !playAndroid" class="android-block" @click="autoPlayAndroid">
      <img class="play" src="../assets/imgs/right.png"/>
    </div>
  </div>
</template>

<script>
import { myMixins } from "../assets/js/mixin";
import p2 from './2'
import p31 from './31'
import p32 from './32'
import p33 from './33'
import p4 from './4'

export default {
  mixins: [myMixins],
  components: {
    p2,
    p31,
    p32,
    p33,
    p4
  },
  data() {
    return {
      idx: 0,
      playAndroid: false
    };
  },
  watch: {
    idx(val) {
      if (val === 0) {
        let video = document.getElementById('video0')
        video.play();
      }
    }
  },
  computed: {
    isAndroidOrIOS() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        return "android"
      }
      if (isiOS) {
        return "ios"
      }
      return false
    },
    isAndroid() {
      return this.isAndroidOrIOS === 'android'
    }
  },
  mounted() {
    if (!this.isAndroid) {
      this.wxConfig()
    }
  },
  methods: {
    wxConfig() {
      wx.config({
        debug: false,
        appId: '111',
        timestamp: '111',
        nonceStr: '111',
        signature: '111',
        jsApiList: []
      })
      wx.ready(()=> {
        let video = document.getElementById('video0')
        video.play();
      });
    },
    autoPlayAndroid() {
      console.log('autoPlayAndroid')
      this.playAndroid = true
      let video = document.getElementById('video0')
      video.play()
      this.wxConfig()
    }
  }
};
</script>

