import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/2',
    query: '/2',
    component: () => import('../views/2.vue')
  },
  {
    path: '/31',
    query: '/31',
    component: () => import('../views/31.vue')
  },
  {
    path: '/32',
    query: '/32',
    component: () => import('../views/32.vue')
  },
  {
    path: '/33',
    query: '/33',
    component: () => import('../views/33.vue')
  },
  {
    path: '/4',
    query: '/4',
    component: () => import('../views/4.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
