
<template>
  <div>
    <div class="home"
         :style="'width:' + winWCur + 'px;height:' + winHCur + 'px;margin-top:' + winTCur + 'px'"
    >
      <div :style="'height:' + winHCur + 'px;' + 'position: relative'">
        <video
            class="video"
            id="video4"
            muted
            autoplay
            loop
            src="../assets/videos/04.mp4"
        ></video>
        <!--首页-->
        <div class="btn1" :class="{cur: idx==0}" @click="clickIdx(1)"></div>
        <div class="btn2" :class="{cur: idx==0}" @click="clickIdx(2)"></div>
        <div class="btn3" :class="{cur: idx==0}" @click="clickIdx(3)"></div>
        <div class="btn4" :class="{cur: idx==0}" @click="clickIdx(4)"></div>
        <div class="btn5" :class="{cur: idx==0}" @click="clickIdx(5)"></div>
        <div class="btn6" :class="{cur: idx==0}" @click="clickIdx(6)"></div>
        <div class="btn7" :class="{cur: idx==0}" @click="clickIdx(7)"></div>
        <div class="btn8" :class="{cur: idx==0}" @click="clickIdx(8)"></div>

        <img class="img-box" v-if="idx==1" src="../assets/imgs/41.png"/>
        <img class="img-box" v-if="idx==2" src="../assets/imgs/42.png"/>
        <img class="img-box" v-if="idx==3" src="../assets/imgs/43.png"/>
        <img class="img-box" v-if="idx==4" src="../assets/imgs/44.png"/>
        <img class="img-box" v-if="idx==5" src="../assets/imgs/45.png"/>
        <img class="img-box" v-if="idx==6" src="../assets/imgs/46.png"/>
        <img class="img-box" v-if="idx==7" src="../assets/imgs/47.png"/>
        <img class="img-box" v-if="idx==8" src="../assets/imgs/48.png"/>

<!--        <div class="btn-left" :class="{cur: idx==0}" @click="idx==0?$router.push({ path:'/33', query: $route.query}):''"></div>-->
        <div class="block-warp" v-if="idx>0" @click="idx=0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { myMixins } from "../assets/js/mixin";
export default {
  mixins: [myMixins],
  data() {
    return {
      idx: 0
    };
  },
  mounted() {
    let video = document.getElementById('video4')
    video.play();
  },
  methods: {
    clickIdx(idx) {
      if (this.idx > 0) {
        return
      }
      this.idx = idx
    }
  }
};
</script>

<style scoped>
.btn1 {
  position: absolute;
  width: 4%;
  height: 4%;
  top: 47%;
  left: 16%;
}
.btn2 {
  position: absolute;
  width: 4%;
  height: 4%;
  top: 44%;
  left: 20.5%;
}
.btn3 {
  position: absolute;
  width: 5.2%;
  height: 4%;
  top: 47%;
  left: 24.8%;
}
.btn4 {
  position: absolute;
  width: 4.5%;
  height: 6.4%;
  top: 34%;
  left: 42%;
}
.btn5 {
  position: absolute;
  width: 5%;
  height: 6.4%;
  top: 34%;
  left: 47.5%;
}
.btn6 {
  position: absolute;
  width: 4%;
  height: 4%;
  top: 56%;
  left: 51.8%;
}
.btn7 {
  position: absolute;
  width: 6%;
  height: 4%;
  top: 44%;
  left: 70.2%;
}
.btn8 {
  position: absolute;
  width: 6.6%;
  height: 4%;
  top: 54%;
  left: 67.5%;
}
</style>
